import Vue from 'vue';
import App from './App.vue';
import router from './router';
import store from './store';
import i18n from './i18n';
import { ObserveVisibility } from 'vue-observe-visibility';

Vue.directive('observe-visibility', ObserveVisibility);

Vue.config.productionTip = false

Vue.directive('observe-visibility', {
  bind(el, binding) {
    const options = {
      root: null,
      rootMargin: '0px',
      threshold: 0.1,
    };

    const observer = new IntersectionObserver((entries, observer) => {
      entries.forEach(entry => {
        if (entry.isIntersecting) {
          binding.value(true, entry);
          observer.unobserve(entry.target);
        }
      });
    }, options);

    observer.observe(el);
  }
});

new Vue({
  router, // 确保路由器被传递给 Vue 实例
  store,
  i18n,
  render: h => h(App)
}).$mount('#app')
