<template>
  <div class="contact-info" v-show="!loading">
    <!-- Banner -->
    <div class="banner">
      <img :src="preloadedImages.contact1" alt="Company Profile" class="banner-img" v-if="preloadedImages.contact1">
      <div class="overlay"></div>
      <div class="banner-text">
        <h1>{{ $t('contactUs.title') }}</h1>
      </div>
    </div>

    <!-- Contact Form -->
    <div id="contact" class="form-section">
      <form @submit.prevent="submitForm" @input="clearCustomValidity">
        <div class="form-group">
          <label for="inquiryType">{{ $t('contactUs.inquiryType') }} <span class="required">{{ $t('contactUs.required') }}</span></label>
          <select class="font-small" id="inquiryType" v-model="form.inquiryType" @change="updateToEmail" @invalid="setCustomValidity" @input="removeInvalidClass" required>
            <option value="" disabled>{{ $t('contactUs.select') }}</option>
            <option :value="$t('contactUs.newGraduate')">{{ $t('contactUs.newGraduate') }}</option>
            <option :value="$t('contactUs.careerRecruitment')">{{ $t('contactUs.careerRecruitment') }}</option>
            <option :value="$t('contactUs.serviceInquiry')">{{ $t('contactUs.serviceInquiry') }}</option>
            <option :value="$t('contactUs.other')">{{ $t('contactUs.other') }}</option>
          </select>
        </div>

        <div class="form-group">
          <label for="lastName">{{ $t('contactUs.name') }} <span class="required">{{ $t('contactUs.required') }}</span></label>
          <div class="name-inputs">
            <input type="text" id="lastName" v-model="form.lastName" :placeholder="$t('contactUs.lastName')" @invalid="setCustomValidity" @input="removeInvalidClass" required>
            <input type="text" id="firstName" v-model="form.firstName" :placeholder="$t('contactUs.firstName')" @invalid="setCustomValidity" @input="removeInvalidClass" required>
          </div>
        </div>

        <div class="form-group">
          <label for="companyName">{{ $t('contactUs.companyName') }} <span class="no-required">{{ $t('contactUs.optional') }}</span></label>
          <input type="text" id="companyName" v-model="form.companyName">
        </div>

        <div class="form-group">
          <label for="departmentName">{{ $t('contactUs.departmentName') }} <span class="no-required">{{ $t('contactUs.optional') }}</span></label>
          <input type="text" id="departmentName" v-model="form.departmentName">
        </div>

        <div class="form-group">
          <label for="email">{{ $t('contactUs.email') }} <span class="required">{{ $t('contactUs.required') }}</span></label>
          <input type="email" id="email" v-model="form.email" @invalid="setCustomValidity" @input="removeInvalidClass" required>
        </div>

        <div class="form-group">
          <label for="phone">{{ $t('contactUs.phone') }} <span class="required">{{ $t('contactUs.required') }}</span></label>
          <input type="tel" id="phone" v-model="form.phone" @invalid="setCustomValidity" @input="removeInvalidClass" required>
        </div>

        <div class="form-group">
          <label for="message">{{ $t('contactUs.message') }} <span class="required">{{ $t('contactUs.required') }}</span></label>
          <textarea id="message" v-model="form.message" rows="5" @invalid="setCustomValidity" @input="removeInvalidClass" required></textarea>
        </div>

        <button type="submit" class="submit-button">{{ $t('contactUs.submitButton') }}</button>
      </form>
    </div>
  </div>
</template>

<script>
import emailjs from 'emailjs-com';

export default {
  name: 'ContactInfo',
  data() {
    return {
      form: {
        inquiryType: '',
        lastName: '',
        firstName: '',
        companyName: '',
        departmentName: '',
        email: '',
        phone: '',
        message: '',
        toEmail: ''
      }
    };
  },
  props: {
    loading: {
      type: Boolean,
      default: true
    },
    preloadedImages: {
      type: Object,
      default: () => ({})
    }
  },
  methods: {
    setCustomValidity(event) {
      const input = event.target;
      if (input.validity.valueMissing) {
        input.setCustomValidity(this.$t('contactUs.validationMessage'));
        input.classList.add('invalid');
      } else {
        input.setCustomValidity('');
        input.classList.remove('invalid');
      }
    },
    clearCustomValidity(event) {
      event.target.setCustomValidity('');
    },
    removeInvalidClass(event) {
      event.target.classList.remove('invalid');
    },
    updateToEmail() {
      if (this.form.inquiryType === this.$t('contactUs.newGraduate') || this.form.inquiryType === this.$t('contactUs.careerRecruitment')) {
        this.form.toEmail = 'recruit@growthsoft.co.jp';
      } else {
        this.form.toEmail = 'info@growthsoft.co.jp';
      }
    },
    submitForm() {
      this.updateToEmail(); // 确保在提交表单时也更新toEmail

      const templateParams = {
        inquiryType: this.form.inquiryType,
        lastName: this.form.lastName,
        firstName: this.form.firstName,
        companyName: this.form.companyName,
        departmentName: this.form.departmentName,
        email: this.form.email,
        phone: this.form.phone,
        message: this.form.message,
        toEmail: this.form.toEmail
      };

      emailjs.send('service_ui6mjpr', 'template_pig9x4m', templateParams, 'Pj3FVBB_yv5G11-kz')
        .then((response) => {
          console.log('SUCCESS!', response.status, response.text);
          alert(this.$t('contactUs.successMessage'));
        }, (error) => {
          console.log('FAILED...', error);
          alert(this.$t('contactUs.errorMessage'));
        });
    }
  },
  mounted() {
    const query = this.$route.query;
    if (query.inquiryType) {
      this.form.inquiryType = query.inquiryType;
      this.updateToEmail(); // 初始化时更新toEmail
    }
  }
};
</script>

<style scoped lang="scss">
@import url('https://fonts.googleapis.com/css2?family=Noto+Sans+JP:wght@400;700&display=swap');

.contact-info {
  text-align: center;
  font-family: 'Noto Sans JP', sans-serif;

  input {
    /* 你可以在这里添加更多样式 */
  }

  .banner {
    position: relative;
    width: 100%;
    height: 420px;
    overflow: hidden;
    margin-bottom: 40px;
    font-family: 'Noto Sans JP', sans-serif;

    &-img {
      width: 100%;
      height: 100%;
      object-fit: cover;
    }

    .overlay {
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      background-color: rgba(0, 0, 0, 0.5);
    }

    .banner-text {
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
      color: white;
      text-align: center;
      
      h1 {
        font-size: 40px;
        letter-spacing: 2px;
        font-weight: 400;
        font-family: 'Noto Sans JP', sans-serif;
      }
    }
  }

  .form-section {
    width: 40%;
    margin: 0 auto;
    padding: 20px;
    background: #fff;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);

    .form-group {
      margin-bottom: 20px;
      text-align: left;

      .font-small {
        padding-left: 15px;
        font-size: 16px;
        color: #414c64;
        letter-spacing: 0.3px;
      }

      label {
        display: block;
        margin-bottom: 5px;
        font-weight: 700;
        font-size: 16px;
        font-weight: 600;
        color: #414c64;
        margin-top: 30px;
        margin-bottom: 15px;
      }

      .no-required {
        padding: 1px 5px 2px 5px;
        margin-left: 4px;
        color: #414c64;
        border: 1px #414c64 solid;
        border-radius: 1px;
        font-size: 11px;
        font-weight: 400;
      }

      .required {
        padding: 1px 5px 2px 5px;
        margin-left: 4px;
        background-color: red;
        border-radius: 1px;
        color: #fff;
        font-size: 11px;
        font-weight: 400;
      }

      input, select {
        width: 100%;
        height: 70px;
        padding: 8px;
        font-size: 16px;
        padding-left: 20px;
        font-size: 18px;
        border: 1px solid #ccc;
        border-radius: 3px;
        box-sizing: border-box;
        border-color: #414c6455;
        font-family: 'Noto Sans JP', sans-serif;
        transition: border-color 0.4s ease;
      }

      textarea {
        width: 100%;
        height: 300px;
        padding: 8px;
        font-size: 16px;
        padding-left: 20px;
        font-size: 18px;
        border: 1px solid #ccc;
        border-radius: 3px;
        box-sizing: border-box;
        border-color: #414c6455;
        letter-spacing: 0.3px;
        font-family: 'Noto Sans JP', sans-serif;
        transition: border-color 0.4s ease;
      }

      input:focus, select:focus, textarea:focus {
        border-color: #414c64d7;
        outline: none;
      }

      input.invalid, select.invalid, textarea.invalid {
        border-color: red;
      }

      .name-inputs {
        display: flex;
        justify-content: space-between;

        input {
          width: 48%;
        }
      }
    }

    .submit-button {
      background-color: #fff;
      color: #414c64;
      padding: 13px 23px;
      margin-bottom: 130px;
      border: 1px #414c64 solid;
      border-radius: 2px;
      cursor: pointer;
      font-size: 16px;
      letter-spacing: 2px;
      transition: background-color 0.6s ease, color 0.6s ease; /* 添加渐进效果 */
    }

    .submit-button:hover {
      background-color: #414c64;
      color: #fff;
    }
  }
}

@media (max-width: 768px) {
  .contact-info {
    .banner {
      height: 320px;

      .banner-text {
        h1 {
          font-size: 24px;
        }
      }
    }

    .form-section {
      width: 100%;
      padding: 20px;
      box-sizing: border-box;

      .form-group {
        margin-bottom: 15px;

        input, select, textarea {
          height: auto;
          font-size: 16px;
        }

        label {
          font-size: 14px;
          margin-top: 20px;
          margin-bottom: 10px;
        }

        .name-inputs {
          flex-direction: column;

          input {
            width: 100%;
            margin-bottom: 10px;
          }
        }
      }

      .submit-button {
        width: 100%;
        font-size: 16px;
        padding: 12px;
        margin-top: 15px;
      }
    }
  }
}
</style>
